import {BlobSvg} from "./BlobStyle";

function Blob () {
    return (
        <BlobSvg viewBox="0 0 720 511" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="url(#paint0_radial_498_15)">
                <animate keySplines="0.65, 0, 0.35, 1; 0.65, 0, 0.35, 1" keyTimes="0; 0.5; 1"  calcMode="spline" fill="freeze" dur="8s" repeatCount="indefinite" attributeName="d" values="
	M200.78 103.913C183.98 91.5131 184.113 45.7465 186.28 24.4131C184.822 -27.5869 30.2798 16.9131 11.2798 34.9131C-7.72021 52.9131 -3.22021 449.413 30.2798 490.413C63.7798 531.413 707.78 504.913 718.28 449.413C728.78 393.913 689.28 328.913 652.28 283.913C615.28 238.913 539.28 249.413 501.78 283.913C464.28 318.413 423.28 314.413 397.78 295.413C372.28 276.413 369.78 198.413 330.78 148.913C291.78 99.4131 221.78 119.413 200.78 103.913Z;
	M186.28 116C169.48 103.6 163.833 44.3333 166 23C164.542 -29 30.28 20.9132 11.28 38.9132C-7.71997 56.9132 -3.21997 453.413 30.28 494.413C63.78 535.413 676 528 686.5 472.5C697 417 710.5 364.5 673.5 319.5C636.5 274.5 548.746 272.05 505.5 299C471 320.5 419.298 304 399 276.5C378.702 249 395.5 186 356.5 136.5C317.5 87 207.28 131.5 186.28 116Z;
	M200.78 103.913C183.98 91.5131 184.113 45.7465 186.28 24.4131C184.822 -27.5869 30.2798 16.9131 11.2798 34.9131C-7.72021 52.9131 -3.22021 449.413 30.2798 490.413C63.7798 531.413 707.78 504.913 718.28 449.413C728.78 393.913 689.28 328.913 652.28 283.913C615.28 238.913 539.28 249.413 501.78 283.913C464.28 318.413 423.28 314.413 397.78 295.413C372.28 276.413 369.78 198.413 330.78 148.913C291.78 99.4131 221.78 119.413 200.78 103.913Z;
	"
                />
            </path>
            <defs>
                <radialGradient id="paint0_radial_498_15" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(385.5 333) rotate(-168.583) scale(393.182 278.728)">
                    <stop offset="0.435" stopColor="#157874"/>
                    <stop offset="1" stopColor="#10864F"/>
                </radialGradient>
            </defs>
        </BlobSvg>

    )
}

export default Blob